import React, { useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft'

import { useMedia } from 'react-use'

/**
 * @param {Object} props
 * @param {Object[]} props.items
 * @param {string} [props.items.displayName] - Display name to override name
 * @param {string} props.items.name
 * @param {string} props.items.link
 */
export const BreadcrumbsNav = props => {
  const { items } = props

  const isMobile = useMedia('(max-width: 899px)')
  /**
   * @type {import('react').MutableRefObject<HTMLInputElement>}
   */
  const ulRef = useRef(null)

  useEffect(() => {
    if (isMobile) {
      ulRef.current.scrollLeft = ulRef.current.scrollWidth
    }
  }, [isMobile])

  return (
    <StyledBreadcrumbsNav aria-label='Breadcrumb'>
      <ul ref={ulRef}>
        {items.map((item, itemIndex) => (
          <BreadcrumbsNavListItem
            key={item.name}
            item={item}
            itemIndex={itemIndex}
            itemsLength={items.length}
          />
        ))}
      </ul>
    </StyledBreadcrumbsNav>
  )
}

/**
 * @param {Object} props
 * @param {Parameters<typeof BreadcrumbsNav>[0]['items'][0]} props.item
 * @param {number} itemIndex
 * @param {number} itemsLength
 */
const BreadcrumbsNavListItem = props => {
  const { item, itemIndex, itemsLength } = props
  const showBackArrow =
    (itemsLength === 2 && itemIndex === 0) ||
    (itemsLength > 2 && itemIndex !== 0 && itemIndex !== itemsLength - 1)
  return (
    <li>
      <Link to={item.link} aria-current={itemIndex === itemsLength - 1 ? 'page' : null}>
        {showBackArrow ? (
          <FiChevronLeft aria-hidden='true' focusable='false' role='presentation' />
        ) : null}
        <span>{item.displayName || item.name}</span>
      </Link>
    </li>
  )
}

const StyledBreadcrumbsNav = styled.nav`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    color: ${props => props.theme.color.n400};
    display: inline-block;
    font-size: 14px;
    margin: 0;
    padding: 0;

    a {
      color: ${props => props.theme.color.g400};
      text-decoration: none;

      :hover,
      :focus {
        text-decoration: underline;
      }
    }

    svg {
      margin: 0 2px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }

    :last-child {
      a {
        color: ${props => props.theme.color.n400};

        :hover,
        :focus {
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: 899px) {
    ul {
      scrollbar-width: none;
      overflow: auto hidden;
      white-space: nowrap;

      ::-webkit-scrollbar {
        display: none;
      }
    }

    li {
      font-size: 16px;

      :nth-last-child(2) {
        width: 100%;
      }

      :last-child {
        display: none;
      }
    }
  }

  @media (min-width: 900px) {
    li {
      :not(:last-child):after {
        content: '/';
        display: inline-block;
        margin: 0 4px;
      }

      svg {
        display: none;
      }
    }
  }
`
